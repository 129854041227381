export default [
    {
        name: 'directorioRevision',
        path: '/directorioRevision',
        component: () => import(/* webpackChunkName: "directorioRevision" */ '../components/revision.vue'),
        meta: {
            title: 'Directorio Exportador',
            middleware: 'auth',
            middlewareRol: ['All'],
            middlewareEmpresa: ['All']
        }
    },
]