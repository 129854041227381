import CryptoJS from 'crypto-js';
export default [
    {
        name: 'registroruex',
        path: '/registroruex',
        component: () => import(/* webpackChunkName: "FormRegistroRuex" */ '../components/registro/FormRegistroRuex.vue'),
        meta: {
            title: 'Registro RUEX',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites'],
            middlewareEmpresa: ['Empresa con Registro Basico']
        }
    },
    {
        name: 'solicitudesruex',
        path: '/solicitudesruex',
        component: () => import(/* webpackChunkName: "SolicitudesRuex" */ '../components/revision/SolicitudesRuex.vue'),
        meta: {
            title: 'Solicitudes RUEX',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'revisionruex',
        path: '/revisionruex/:id_ruex_solicitud',
        component: () => import(/* webpackChunkName: "FormRevisionRuex" */ '../components/revision/FormRevisionRuex.vue'),
        props: (route) => {
            const { id_ruex_solicitud } = route.params
            var eg = id_ruex_solicitud.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_solicitud_decry = parseInt(eg)
            return { id_ruex_solicitud: id_solicitud_decry }
        },
        meta: {
            title: 'Revision RUEX',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'solicitudesrenovacionruex',
        path: '/solicitudesrenovacionruex',
        component: () => import(/* webpackChunkName: "SolicitudesRenovacionRuex" */ '../components/revision-renovar/SolicitudesRenovacionRuex.vue'),
        meta: {
            title: 'Solicitudes Renovacion RUEX',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'revisionrenovacionruex',
        path: '/revisionrenovacionruex/:id_ruex_solicitud',
        component: () => import(/* webpackChunkName: "FormReviRenRuex" */ '../components/revision-renovar/FormReviRenRuex.vue'),
        props: (route) => {
            const { id_ruex_solicitud } = route.params
            var eg = id_ruex_solicitud.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_solicitud_decry = parseInt(eg)
            return { id_ruex_solicitud: id_solicitud_decry }
        },
        meta: {
            title: 'Revision Renovacion RUEX',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'editarruex',
        path: '/editarruex',
        component: () => import(/* webpackChunkName: "FormEdicionRuex" */ '../components/edicion/FormEdicionRuex.vue'),
        meta: {
            title: 'Edición RUEX',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites'],
            middlewareEmpresa: ['Empresa Rechazada', 'Empresa con RUEX - Rechazada']
        }
    },
    {
        name: 'actualizarruex',
        path: '/actualizarruex',
        component: () => import(/* webpackChunkName: "ActualizarRuex" */  '../components/actualizar/ActualizarRuex.vue'),
        meta: {
            title: 'Actualizar RUEX',
            middleware: 'auth',
            middlewareRol: ['Representante Legal'],
            middlewareEmpresa: ['Empresa con RUEX']
        }
    },
    {
        name: 'completarruex',
        path: '/completarruex',
        component: () => import(/* webpackChunkName: "CompletarRuex" */  '../components/completar/CompletarRuex.vue'),
        meta: {
            title: 'Completar datos RUEX',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites'],
            middlewareEmpresa: ['Empresa con RUEX', 'Empresa con RUEX - Vencido']
        }
    },
    {
        name: 'renovarruex',
        path: '/renovarruex',
        component: () => import(/* webpackChunkName: "RenovarRuex" */  '../components/renovar/RenovarRuex.vue'),
        meta: {
            title: 'Renovar RUEX',
            middleware: 'auth',
            middlewareRol: ['Representante Legal'],
            middlewareEmpresa: ['Empresa con RUEX', 'Empresa con RUEX - Vencido', 'Empresa con RUEX - Rechazada']
        }
    },
    {
        name: 'pendientepago',
        path: '/pendientepago',
        component: () => import(/* webpackChunkName: "RenovarRuex" */  '../components/pago/PendientePago.vue'),
        meta: {
            title: 'Pendiente de Pago',
            middleware: 'auth',
            middlewareRol: ['Representante Legal'],
            middlewareEmpresa: ['Empresa Pendiente de Pago', 'Empresa con RUEX - Pendiente de Pago']
        }
    },
    {
        name: 'bajaruex',
        path: '/bajaruex',
        component: () => import(/* webpackChunkName: "BajaRuex" */  '../components/baja/BajaRuex.vue'),
        meta: {
            title: 'Baja RUEX',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'listaruex',
        path: '/listaruex',
        component: () => import(/* webpackChunkName: "ListaRuex" */  '../components/lista/ListaRuex.vue'),
        meta: {
            title: 'Lista RUEX',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente', 'Analista UCO', 'Jefatura UCLAJ', 'Jefatura UCO', 'Super Admin','Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'listapersonastaypi',
        path: '/listapersonastaypi',
        component: () => import(/* webpackChunkName: "ListaPersonas" */  '../components/lista/ListaPersonas.vue'),
        meta: {
            title: 'Lista Personas TAYPI',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente', 'Analista UCO', 'Jefatura UCLAJ', 'Jefatura UCO', 'Super Admin','Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'listapersonasvortex',
        path: '/listapersonasvortex',
        component: () => import(/* webpackChunkName: "ListaPersonasVortex" */  '../components/lista/ListaPersonasVortex.vue'),
        meta: {
            title: 'Lista Personas VORTEX',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente', 'Analista UCO', 'Jefatura UCLAJ', 'Jefatura UCO', 'Super Admin','Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'subsanablesruex',
        path: '/subsanablesruex',
        component: () => import(/* webpackChunkName: "Subsanables" */  '../components/subsanables/ListaSubsanable.vue'),
        meta: {
            title: 'Subsanables RUEX',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites'],
            middlewareEmpresa: ['Empresa con RUEX']
        }
    },
    {
        name: 'editGeoreferenciacion',
        path: '/editGeoreferenciacion',
        component: () => import(/* webpackChunkName: "Subsanables" */  '../components/subsanables/Georeferenciacion.vue'),
        meta: {
            title: 'Subsanables RUEX',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites'],
            middlewareEmpresa: ['Empresa con RUEX']
        }
    },
]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       