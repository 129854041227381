export default [
    {
        name: 'usuariosIndex',
        path: '/usuariosIndex',
        component: () => import(/* webpackChunkName: "IndexUsuarios" */ '../components/accesos/IndexUsuarios.vue'),
        meta: {
            title: 'Usuario - Accesos.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Analista UCO','Asistente','Jefatura USP', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        },
    },

    {
        name: 'fnacimientoIndex',
        path: '/fnacimientoIndex',
        component: () => import(/* webpackChunkName: "IndexFNacimiento" */ '../components/accesos/IndexFNacimiento.vue'),
        meta: {
            title: 'Usuario - Fecha Nacimiento.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Analista UCO','Asistente','Jefatura USP', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

    {
        name: 'facturaCptIndex',
        path: '/facturaCptIndex',
        component: () => import(/* webpackChunkName: "IndexCptFacturas" */ '../components/facturas/IndexCptFacturas.vue'),
        meta: {
            title: 'Cpt - Factura.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Analista UCO','Asistente','Jefatura USP', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

    {
        name: 'revisorIndex',
        path: '/revisorIndex',
        component: () => import(/* webpackChunkName: "IndexRevisor" */ '../components/revisor/IndexRevisor.vue'),
        meta: {
            title: 'Revisores.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Analista UCO','Jefatura USP', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

    {
        name: 'empresaMatricula',
        path: '/empresaMatricula',
        component: () => import(/* webpackChunkName: "IndexCptFacturas" */ '../components/matricula/empresaMatricula.vue'),
        meta: {
            title: 'Cpt - Factura.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Analista UCO','Asistente','Jefatura USP', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
]