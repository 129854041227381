import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import VueSweetalert2 from 'vue-sweetalert2';

import oic from '../oic/router';
import ruex from '../ruex/router';
import auth from '../auth/router';
import admin from '../admin/router'
import reports from '../reports/router';
import helpers from '../helpers/router';
import ddjj from '../ddjj/router';
import co from '../co/router';
import facturacion from '../facturacion/router';
import usuarios from '../usuarios/router';
import rrhh from '../rrhh/router';
import cambios from '../modificaciones/router';
import qys from '../qys/router';
import graficos from '../graficos/router';
import directorio from '../directorio/router';

Vue.use(VueRouter)
Vue.use(VueSweetalert2);

const roles = ['Super Admin', 'Representante Legal', 'Registrar y Firmar Trámites', 'Registrar Trámites',
  'Asistente', 'Certificador', 'Responsable', 'Analista UCO', 'Jefatura UCLAJ', 'Tributación', 'Mae',
  'Recursos Humanos', 'Secretaria', 'Jefatura UCO', 'Jefatura USP', 'Jefatura UAF', 'Planificación', 'Contabilidad',
  'Gestión Financiera y Presupuestos', 'Bienes y Servicios  y Activos Fijos', 'Servicios Generales, Almacenes y Archivo',
  'Auxiliar de Oficina', 'Chofer y Mensajero']

const estadosEmpresa = ['Empresa con Registro Basico', 'Institución Revisora/Certificadora', 'Empresa con RUEX',
  'Empresa Rechazada', 'Empresa con RUEX - Vencido', 'Empresa con RUEX - Rechazada', 'Empresa Pendiente de Pago']

const home = [
  {
    name: 'home',
    path: '/',
    component: () => import(/* webpackChunkName: "Home" */  '../Home.vue'),
    meta: {
      title: 'Inicio',
      middleware: 'auth',
      middlewareRol: ['All'],
      middlewareEmpresa: ['All']
    },
    children: admin.concat(helpers, ruex, reports, oic, ddjj, co, facturacion, usuarios, rrhh, cambios, qys, graficos, directorio)
  },
  {
    name: 'verificacion',
    path: '/ruex/verificacion/:nro',
    component: () => import(/* webpackChunkName: "Verificacion" */ '../views/Verificacion.vue'),
    props: (route) => {
      return { nro: route.params.nro }
    },
    meta: {
      title: 'Verificacion RUEX',
      middleware: ['auth', 'guest'],
      middlewareRol: ['All'],
      middlewareEmpresa: ['All']
    }
  },
  {
    name: 'verificarco',
    path: '/verificarco',
    component: () => import(/* webpackChunkName: "VerificacionCo" */ '../views/VerificacionCo.vue'),
    meta: {
      title: 'Verificacion CO',
      middleware: ['auth', 'guest'],
      middlewareRol: ['All'],
      middlewareEmpresa: ['All']
    }
  },
  {
    name: 'registroqyspub',
    path: '/qys/registroqyspub',
    component: () => import(/* webpackChunkName: "FormRegistroQysPub" */ '../views/FormRegistroQysPub.vue'),
    meta: {
      title: 'Registro QyS PUB',
      middleware: 'guest',
      middlewareRol: ['All'],
      middlewareEmpresa: ['All']
    }
  },
  {
    name: '120dias_pub',
    path: '/co/120dias_pub',
    component: () => import(/* webpackChunkName: "120dias_pub" */ '../views/120dias_pub.vue'),
    meta: {
      title: 'Certificados vendidos con mas de 120 dias',
      middleware: 'guest',
      middlewareRol: ['All'],
      middlewareEmpresa: ['All']
    }
  },
  {
    name: 'notfoundcomponent',
    path: '*',
    component: () => import(/* webpackChunkName: "NotFoundComponent" */ '../views/NotFoundComponent.vue'),
    meta: {
      title: 'No Encontrado',
      middleware: 'auth',
      middlewareRol: ['All'],
      middlewareEmpresa: ['All']
    }
  }
]

const routes = auth.concat(home)

var router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} - ${process.env.APP_NAME}`
  if (to.meta.middleware.includes('guest')) {
    if (store.state.auth.authenticated && to.name != 'verificacion') {
      next({ name: 'home' })
    }
    else {
      next()
    }
  } else {
    if (store.state.auth.authenticated) {
      if (Object.keys(store.state.auth.empresa).length === 0 && to.name != 'selectEmpresa') {
        next({ name: 'selectEmpresa' })
      }
      else {
        if (to.meta.middlewareRol.includes('All')) {
          next()
        }
        else if (to.meta.middlewareRol.some(item => roles.includes(item))) {
          if (to.meta.middlewareRol.includes(store.state.auth.empresa.rol)) {
            if (to.meta.middlewareEmpresa.includes('All')) {
              next()
            }
            else if (to.meta.middlewareEmpresa.some(item => estadosEmpresa.includes(item))) {
              if (to.meta.middlewareEmpresa.includes(store.state.auth.empresa.descripcion_estado)) {
                if ((Boolean(store.state.auth.empresa.id_departamento) && Boolean(store.state.auth.empresa.id_municipio) &&
                  Boolean(store.state.auth.empresa.direccion_fiscal) && Boolean(store.state.auth.empresa.direccion_descriptiva) &&
                  Boolean(store.state.auth.empresa.email)) || to.name.includes('completarruex') || to.name.includes('registroruex')) {
                  next()
                }
                else {
                  Vue.swal.fire("COMPLETAR LOS DATOS", "Debe completar los siguientes datos para continuar con cualquier accion en la plataforma TAYPI", "error");
                  next({ name: 'completarruex' })
                }
              }
              else {
                to.meta.middlewareEmpresa.includes('Empresa con RUEX') ? Vue.swal.fire("RUEX NO ACTIVO", "Debe Renovar/Obtener su RUEX", "error") : Vue.swal.fire("ACCESO DENEGADO", "La empresa no cumple los requisitos", "error")
                next({ name: 'home' })
              }
            }
            else {
              Vue.swal.fire("ACCESO DENEGADO", "La empresa no cumple los requisito", "error");
              next({ name: 'home' })
            }
          }
          else {
            Vue.swal.fire("ACCESO DENEGADO", "El usuario no cuenta con permisos", "error");
            next({ name: 'home' })
          }
        }
        else {
          Vue.swal.fire("ACCESO DENEGADO", "El usuario no cuenta con permisos", "error");
          next({ name: 'home' })
        }
      }
    } else {
      next({ name: 'login' })
    }
  }
})

export default router