import CryptoJS from 'crypto-js';
export default [
    {
        name: 'listaSeguimientoDdjj',
        path: '/listaSeguimientoDdjj',
        component: () => import(/* webpackChunkName: "listaSeguimientoDdjj" */ '../components/ddjj/listaSeguimientoDdjj.vue'),
        meta: {
            title: 'Aprobacion de Cambios.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Analista UCO', 'Asistente', 'Jefatura UCO', 'Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

    {
        name: 'solicitudesModificacionDdjj',
        path: '/solicitudesModificacionDdjj',
        component: () => import(/* webpackChunkName: "IndexRevision" */ '../components/ddjj/solicitudesModificacionDdjj.vue'),
        meta: {
            title: 'Aprobacion de Cambios.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Analista UCO', 'Asistente', 'Jefatura USP', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

    {
        name: 'busquedaModificacionDdjj',
        path: '/busquedaModificacionDdjj',
        component: () => import(/* webpackChunkName: "busquedaModificacionDdjj" */ '../components/ddjj/busquedaModificacionDdjj.vue'),
        meta: {
            title: 'Usuario - Accesos.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Analista UCO', 'Asistente', 'Jefatura USP', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        },
    },

    {
        name: 'listaObservadasDdjj',
        path: '/listaObservadasDdjj',
        component: () => import(/* webpackChunkName: "IndexRevision" */ '../components/ddjj/listaObservadasDdjj.vue'),
        meta: {
            title: 'Lista de DDJJ Observadas.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Analista UCO', 'Asistente', 'Jefatura USP', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

    {
        name: 'listaSeguimientoRuex',
        path: '/listaSeguimientoRuex',
        component: () => import(/* webpackChunkName: "listaSeguimientoRuex" */ '../components/ruex/listaSeguimientoRuex.vue'),
        meta: {
            title: 'Aprobacion de Cambios.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Analista UCO', 'Asistente', 'Jefatura USP', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

    {
        name: 'listaObservadasRuex',
        path: '/listaObservadasRuex',
        component: () => import(/* webpackChunkName: "listaObservadasRuex" */ '../components/ruex/listaObservadasRuex.vue'),
        meta: {
            title: 'Lista de RUEX Observados.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Analista UCO', 'Asistente', 'Jefatura USP', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'solicitudesModificacionCO',
        path: '/solicitudesModificacionCO',
        component: () => import(/* webpackChunkName: "SolicitudModificacionCO" */ '../components/co/solicitudesModificacionCO.vue'),
        meta: {
            title: 'Aprobacion de Cambios.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Analista UCO', 'Asistente', 'Jefatura USP', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

    {
        name: 'busquedaModificacionCO',
        path: '/busquedaModificacionCO',
        component: () => import(/* webpackChunkName: "busquedaModificacionCO" */ '../components/co/busquedaModificacionCO.vue'),
        meta: {
            title: 'Usuario - Accesos.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Analista UCO', 'Asistente', 'Jefatura USP', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        },
    },

    {
        name: 'listaSeguimientoCo',
        path: '/listaSeguimientoCo',
        component: () => import(/* webpackChunkName: "listaSeguimientoCo" */ '../components/co/listaSeguimientoCo.vue'),
        meta: {
            title: 'Aprobacion de Cambios.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Analista UCO', 'Asistente', 'Jefatura USP', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

    {
        name: 'ver_co',
        path: '/ver_co/:id_co',
        component: () => import(/* webpackChunkName: "VerCO" */ '../components/co/Ver_co.vue'),
        props: (route) => {
            const { id_co } = route.params
            var eg = id_co.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_co_decry = parseInt(eg)
            return { id_co: id_co_decry }
        },
        meta: {
            title: 'Ver CO',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Analista UCO', 'Jefatura USP', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora', 'Empresa con RUEX']
        }
    },

    {
        name: 'listaObservadasCo',
        path: '/listaObservadasCo',
        component: () => import(/* webpackChunkName: "listaObservadasCo" */ '../components/co/listaObservadasCo.vue'),
        meta: {
            title: 'Lista de CO Observadas.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Analista UCO', 'Asistente', 'Jefatura USP', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

    {
        name: 'listaMercaderiaCo',
        path: '/listaMercaderiaCo',
        component: () => import(/* webpackChunkName: "listaMercaderiaCo" */ '../components/co/listaMercaderiaCo.vue'),
        props: (route) => {
            return { id_co: route.params.id_co }
        },
        meta: {
            title: 'Mercaderias.',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Analista UCO', 'Asistente', 'Jefatura USP', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'crearticket',
        path: '/crearticket',
        component: () => import(/* webpackChunkName: "CrearTicket" */ '../components/ticket/CrearTicket.vue'),
        meta: {
            title: 'Crear Ticket.',
            middleware: 'auth',
            middlewareRol: ['All'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'listtickets',
        path: '/listtickets',
        component: () => import(/* webpackChunkName: "ListTickets" */ '../components/ticket/ListTickets.vue'),
        meta: {
            title: 'Tickets',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'ticket',
        path: '/ticket/:id_modificaciones',
        component: () => import(/* webpackChunkName: "Ticket" */ '../components/ticket/Ticket.vue'),
        props: (route) => {
            const { id_modificaciones } = route.params
            var eg = id_modificaciones.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_modificaciones_decry = parseInt(eg)
            return { id_modificaciones: id_modificaciones_decry }
        },
        meta: {
            title: 'Ticket',
            middleware: 'auth',
            middlewareRol: ['All'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
]