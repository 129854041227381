export const set_id_empresa_ddjj = (state, value) => {
    state.id_empresa_ddjj = value
}

export const set_actividad_economica = (state, value) => {
    state.actividad_economica = value
}

export const set_id_ddjj = (state, value) => {
    state.id_ddjj = value
}

export const set_id_ddjj_tipo = (state, value) => {
    state.id_ddjj_tipo = value
}

export const set_sigla = (state, value) => {
    state.sigla = value
}

export const set_acuerdo = (state, value) => {
    state.acuerdo = value
}

export const set_arancel = (state, value) => {
    state.arancel = value
}
export const set_id_ddjj_arancel = (state, value) => {
    state.id_ddjj_arancel = value
}

export const set_valor = (state, value) => {
    state.valor = value
}

export const set_accion = (state, value) => {
    state.accion = value
}

export const set_fob_total = (state, value) => {
    state.fob_total = value
}

export const set_fob_sumatoria = (state, value) => {
    state.fob_sumatoria = value
}

export const set_fob_porcentaje_sumatoria = (state, value) => {
    state.fob_porcentaje_sumatoria = value
}

export const set_fob_restante = (state, value) => {
    state.fob_restante = value
}
