export const SET_AUTHENTICATED = (state, value) => {
    state.authenticated = value
}

export const SET_PERSONA = (state, value) => {
    state.persona = value
}

export const SET_EMPRESAS = (state, value) => {
    state.empresas = value
}

export const SET_EMPRESA = (state, value) => {
    state.empresa = value
}