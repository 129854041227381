export default [
    {
        name: 'ddjjindex',
        path: '/ddjjindex',
        component: () => import(/* webpackChunkName: "IndexDdjj" */ '../components/registro/IndexDdjj.vue'),
        meta: {
            title: 'Declaraciones Juradas',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites'],
            middlewareEmpresa: ['Empresa con RUEX']
        }
    },

    {
        name: 'ddjjHabilitadas',
        path: '/ddjjHabilitadas',
        component: () => import(/* webpackChunkName: "IndexDdjj" */ '../components/listasExp/ddjjHabilitadas.vue'),
        meta: {
            title: 'Declaraciones Juradas',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites'],
            middlewareEmpresa: ['Empresa con RUEX']
        }
    },

    {
        name: 'ddjjVencidas',
        path: '/ddjjVencidas',
        component: () => import(/* webpackChunkName: "IndexDdjj" */ '../components/listasExp/ddjjVencidas.vue'),
        meta: {
            title: 'Declaraciones Juradas',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites'],
            middlewareEmpresa: ['Empresa con RUEX']
        }
    },

    {
        name: 'ddjjAprobadasCert',
        path: '/ddjjAprobadasCert',
        component: () => import(/* webpackChunkName: "IndexDdjj" */ '../components/listasCert/ddjjAprobadasCert.vue'),
        meta: {
            title: 'Declaraciones Juradas',
            middleware: 'auth',
            middlewareRol: ['All'],
            middlewareEmpresa: ['All']
        }
    },

    {
        name: 'ddjjHabilitadasCert',
        path: '/ddjjHabilitadasCert',
        component: () => import(/* webpackChunkName: "IndexDdjj" */ '../components/listasCert/ddjjHabilitadasCert.vue'),
        meta: {
            title: 'Declaraciones Juradas',
            middleware: 'auth',
            middlewareRol: ['All'],
            middlewareEmpresa: ['All']
        }
    },

    {
        name: 'ddjjVisitasCert',
        path: '/ddjjVisitasCert',
        component: () => import(/* webpackChunkName: "IndexDdjj" */ '../components/listasCert/ddjjVisitasCert.vue'),
        meta: {
            title: 'Declaraciones Juradas',
            middleware: 'auth',
            middlewareRol: ['All'],
            middlewareEmpresa: ['All']
        }
    },

    {
        name: 'ddjjBajasCert',
        path: '/ddjjBajasCert',
        component: () => import(/* webpackChunkName: "IndexDdjj" */ '../components/listasCert/ddjjBajasCert.vue'),
        meta: {
            title: 'Declaraciones Juradas',
            middleware: 'auth',
            middlewareRol: ['All'],
            middlewareEmpresa: ['All']
        }
    },

    {
        name: 'ddjjregistro',
        path: '/ddjjregistro',
        component: () => import(/* webpackChunkName: "RegistroDdjj" */ '../components/registro/FormRegistroDdjj.vue'),
        meta: {
            title: 'Declaraciones Juradas',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites', 'Certificador', 'Responsable'],
            middlewareEmpresa: ['Empresa con RUEX', 'Institución Revisora/Certificadora']
        }
    },

    {
        name: 'ddjjdeposito',
        path: '/ddjjdeposito',
        component: () => import(/* webpackChunkName: "DepositoDdjj" */ '../components/registro/DepositoDdjj.vue'),
        meta: {
            title: 'Declaraciones Juradas - Deposito',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites'],
            middlewareEmpresa: ['Empresa con RUEX']
        }
    },

    {
        name: 'ddjjRevision',
        path: '/ddjjRevision',
        component: () => import(/* webpackChunkName: "RevisionDdjj" */ '../components/revision/IndexDdjj.vue'),
        meta: {
            title: 'Declaraciones Juradas - Deposito',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

    {
        name: 'ddjjver',
        path: '/ddjjver',
        component: () => import(/* webpackChunkName: "VerDdjj" */ '../components/ver/FormRegistroDdjj.vue'),
        meta: {
            title: 'Declaraciones Juradas',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites', 'Certificador', 'Responsable', 'Super Admin', 'Analista UCO', 'Jefatura UCO', 'Jefatura USP'],
            middlewareEmpresa: ['Empresa con RUEX', 'Institución Revisora/Certificadora']
        }
    },

    {
        name: 'ddjjCriterioOrigen',
        path: '/ddjjCriterioOrigen',
        component: () => import(/* webpackChunkName: "gestionCriterioOrigen" */ '../components/criterioOrigen/index.vue'),
        meta: {
            title: 'Declaraciones Juradas',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites', 'Certificador', 'Responsable', 'Super Admin', 'Analista UCO', 'Jefatura UCO', 'Jefatura USP'],
            middlewareEmpresa: ['Empresa con RUEX', 'Institución Revisora/Certificadora']
        }
    },

]