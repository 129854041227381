export default () => ({
    id_empresa_ddjj: null,
    actividad_economica: null,
    id_ddjj: null,
    id_ddjj_tipo: null,
    sigla:null,
    acuerdo:null,
    arancel:null,
    id_ddjj_arancel:null,
    valor:null,
    accion:null,
    fob_total:0.0,
    fob_sumatoria:0.0,
    fob_porcentaje_sumatoria:0.0,
    fob_restante:0.0,
})