export const SET_METODOS_PAGO = (state, value) => {
    state.metodospago = value
}

export const SET_SERVICIOS_COMPRA_CO = (state, value) => {
    state.serviciosCompraCo = value
}

export const SET_SERVICIOS_SENAVEX = (state, value) => {
    state.serviciosSenavex = value
}
