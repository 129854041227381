import router from '../../router'

export const login = async ({ commit }) => {
    return axios.get('/api/persona').then((response) => {
        //si no existe persona
        commit('SET_PERSONA', response.data.data)
        commit('SET_AUTHENTICATED', true)
        router.replace({ name: 'selectEmpresa' })
    }).catch((response) => {
        commit('SET_PERSONA', {})
        commit('SET_AUTHENTICATED', false)
    })
}

export const logout = async ({ commit }) => {
    commit('SET_PERSONA', {})
    commit('SET_AUTHENTICATED', false)
    commit('SET_EMPRESAS', [])
    commit('SET_EMPRESA', {})
}

export const empresasDePersona = async ({ commit }) => {
    axios.get('/api/persona/empresas').then((response) => {
        commit('SET_EMPRESAS', response.data.data)
    }).catch(({ response }) => {
        if (response.status === 468) {
            Vue.swal.fire({
                icon: "error",
                title: response.data.message,
                text: "Intente nuevamente, si el error periste contacte a soporte@senavex.gob.bo",
            });
        } else if (response.status === 400) {
            Vue.$swal.fire({
                icon: "error",
                title: response.data.message,
                text: "Intente nuevamente, si el error periste contacte a soporte@senavex.gob.bo",
            });
        }
        commit('SET_EMPRESAS', [])
    })
}

export const updateEmpresa = async ({ commit, state }) => {
    await axios.get('/api/empresa/' + state.empresa.id_empresa + '/basic').then((response) => {
        if (response.data.data === null) {
            axios.post("/api/logout").then(({ data }) => {
                commit('SET_PERSONA', {})
                commit('SET_AUTHENTICATED', false)
                commit('SET_EMPRESAS', [])
                commit('SET_EMPRESA', {})

                router.replace({ name: 'login' })
            });
        }
        commit('SET_EMPRESA', response.data.data)
    })
}

export const updatePersona = async ({ commit, state }) => {
    await axios.get('/api/persona').then((response) => {
        commit('SET_PERSONA', response.data.data)
    })
}

