export default [
    {
        name: 'calculadora',
        path: '/calculadora',
        component: () => import(/* webpackChunkName: "Calculadora" */  '../components/registro/Calculadora.vue'),
        meta: {
            title: 'Calculadora OIC',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites', 'Registrar Trámites', 'Asistente', 'Certificador', 'Responsable', 'Jefatura UCO'],
            middlewareEmpresa: ['Empresa con RUEX', 'Institución Revisora/Certificadora']
        }
    },
    {
        name: 'formoics',
        path: '/formulariocafe',
        component: () => import(/* webpackChunkName: "FormRegistroOic" */  '../components/registro/FormRegistroOic.vue'),
        meta: {
            title: 'Formulario OIC',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites'],
            middlewareEmpresa: ['Empresa con RUEX']
        }
    },
    {
        name: 'reformoics',
        path: '/reformulariocafe',
        component: () => import(/* webpackChunkName: "RevisionComponent" */  '../components/revision/RevisionComponent.vue'),
        meta: {
            title: 'Reformoics',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'revisioncafe',
        path: '/revisioncafe',
        component: () => import(/* webpackChunkName: "FormRevisionCafe" */ '../components/revision/FormRevisionCafe.vue'),
        meta: {
            title: 'Revision CAFE',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'modificacioncafe',
        path: '/modificacioncafe',
        component: () => import(/* webpackChunkName: "FormEdicionOic" */ '../components/edicion/FormEdicionOic.vue'),
        meta: {
            title: 'Modificación Certificado',
            middleware: 'auth',
            middlewareRol: ['Representante Legal'],
            middlewareEmpresa: ['Empresa con RUEX']
        }
    },
    {
        name: 'certcafe',
        path: '/certcafe',
        component: () => import(/* webpackChunkName: "CafeListexp" */ '../components/revision/CafeListexp.vue'),
        meta: {
            title: 'Certificados',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites'],
            middlewareEmpresa: ['Empresa con RUEX']
        }
    },
    {
        name: 'recertcafe',
        path: '/recertcafe',
        component: () => import(/* webpackChunkName: "CafeListcert" */ '../components/revision/CafeListcert.vue'),
        meta: {
            title: 'Certificados Export',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },

]