import CryptoJS from 'crypto-js';
export default [
    {
        name: 'listcos',
        path: '/listcos',
        component: () => import(/* webpackChunkName: "ListaCo" */ '../components/registro/ListaCo.vue'),
        meta: {
            title: 'Lista CO',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites'],
            middlewareEmpresa: ['Empresa con RUEX']
        }
    },
    {
        name: 'registroco',
        path: '/registroco/:id_co',
        component: () => import(/* webpackChunkName: "FormRegistroCo" */ '../components/registro/FormRegistroCo.vue'),
        props: (route) => {
            const { id_co } = route.params
            var eg = id_co.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_co_decry = parseInt(eg)
            return { id_co: id_co_decry }
        },
        meta: {
            title: 'Registrar CO',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites'],
            middlewareEmpresa: ['Empresa con RUEX']
        }
    },
    {
        name: 'coemitidos',
        path: '/coemitidos',
        component: () => import(/* webpackChunkName: "ListaCoEmitidos" */ '../components/emitidos/ListaCoEmitidos.vue'),
        meta: {
            title: 'Emitidos CO',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites'],
            middlewareEmpresa: ['Empresa con RUEX']
        }
    },
    {
        name: 'coemitidosacumulado',
        path: '/coemitidosacumulado',
        component: () => import(/* webpackChunkName: "ListaCoEmitidosAcumulado" */ '../components/emitidos/ListaCoEmitidosAcumulado.vue'),
        meta: {
            title: 'Emitidos Acumulado CO',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites'],
            middlewareEmpresa: ['Empresa con RUEX']
        }
    },
    {
        name: 'coemitidosanulados',
        path: '/coemitidosanulados',
        component: () => import(/* webpackChunkName: "ListaCoAnuladosVencidos" */ '../components/emitidos/ListaCoAnuladosVencidos.vue'),
        meta: {
            title: 'Anulados CO',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites'],
            middlewareEmpresa: ['Empresa con RUEX']
        }
    },
    //vista CERT
    {
        name: 'coaprobados',
        path: '/coaprobados',
        component: () => import(/* webpackChunkName: "CoAprobados" */ '../components/lists/CoAprobados.vue'),
        meta: {
            title: 'CO Aprobados',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'coemitidosrev',
        path: '/coemitidosrev',
        component: () => import(/* webpackChunkName: "CoEmitidos" */ '../components/lists/CoEmitidos.vue'),
        meta: {
            title: 'CO Emitidos',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'solicitudesco',
        path: '/solicitudesco',
        component: () => import(/* webpackChunkName: "ListEmisionCo" */ '../components/emision/ListEmisionCo.vue'),
        meta: {
            title: 'Emision CO',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'revisioncod',
        path: '/revisioncod/:id_co_solicitud/:id_co/:digital',
        component: () => import(/* webpackChunkName: "RevisionCod" */ '../components/emision/digital/RevisionCod.vue'),
        props: (route) => {
            const { id_co_solicitud, id_co, digital } = route.params;

            var eg = id_co_solicitud.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_co_solicitud_decry = parseInt(eg);

            var eg = id_co.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_co_decry = parseInt(eg);

            return { id_co_solicitud: id_co_solicitud_decry, id_co: id_co_decry, digital: digital }
        },
        meta: {
            title: 'Revision CO',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'verco',
        path: '/verco/:id_co',
        component: () => import(/* webpackChunkName: "VerCO" */ '../components/lists/VerCO.vue'),
        props: (route) => {
            const { id_co } = route.params
            var eg = id_co.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_co_decry = parseInt(eg)
            return { id_co: id_co_decry }
        },
        meta: {
            title: 'Ver CO',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente', 'Representante Legal', 'Registrar y Firmar Trámites', 'Analista UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora', 'Empresa con RUEX']
        }
    },
    {
        name: 'vercod',
        path: '/vercod/:id_co',
        component: () => import(/* webpackChunkName: "VerCO" */ '../components/lists/VerCOD.vue'),
        props: (route) => {
            const { id_co } = route.params
            var eg = id_co.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_co_decry = parseInt(eg)
            return { id_co: id_co_decry }
        },
        meta: {
            title: 'Ver CO',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente', 'Representante Legal', 'Registrar y Firmar Trámites', 'Analista UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora', 'Empresa con RUEX']
        }
    },
    {
        name: 'emisionco',
        path: '/emisionco',
        component: () => import(/* webpackChunkName: "EmisionCo" */ '../components/emision/EmisionCo.vue'),
        meta: {
            title: 'Emision CO',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'asignarcontrolco',
        path: '/asignarcontrolco',
        component: () => import(/* webpackChunkName: "AsignarNroControl" */ '../components/compra/AsignarNroControl.vue'),
        meta: {
            title: 'Asignar Nro Con CO',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'anularco',
        path: '/anularco',
        component: () => import(/* webpackChunkName: "AnularCo" */ '../components/anulacion/AnularCo.vue'),
        meta: {
            title: 'Anular CO',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: '120dias',
        path: '/120dias',
        component: () => import(/* webpackChunkName: "120dias" */ '../components/lists/120dias.vue'),
        meta: {
            title: 'Certificados vendidos con mas de 120 dias',
            middleware: 'auth',
            middlewareRol: ['All'],
            middlewareEmpresa: ['All']
        }
    },
    {
        name: 'helistcods',
        path: '/helistcods',
        component: () => import(/* webpackChunkName: "HeListCods" */ '../components/he/HeListCods.vue'),
        meta: {
            title: 'Lista de CODs para HE',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Jefatura USP', 'Analista UCO', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'hecocod',
        path: '/hecocod/:id_co/:nro_control/:nro_emision/:codigo',
        component: () => import(/* webpackChunkName: "HeCod" */ '../components/he/HeCod.vue'),
        props: (route) => {
            const { id_co, nro_control, nro_emision, codigo } = route.params
            var eg = id_co.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_co_decry = parseInt(eg)
            return { id_co: id_co_decry, nro_control, nro_emision, codigo }
        },
        meta: {
            title: 'Generar COD para HE',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Jefatura USP', 'Analista UCO', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'registrocod',
        path: '/registrocod/:id_cod',
        component: () => import(/* webpackChunkName: "Cod" */ '../components/registro/digital/Cod.vue'),
        props: (route) => {
            const { id_cod } = route.params
            var eg = id_cod.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_cod_decry = parseInt(eg)
            return { id_cod: id_cod_decry }
        },
        meta: {
            title: 'Registrar COD',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites'],
            middlewareEmpresa: ['Empresa con RUEX']
        }
    },
    {
        name: 'codxml',
        path: '/codxml/:id_cod/:codigo',
        component: () => import(/* webpackChunkName: "CodXml" */ '../components/registro/digital/CodXml.vue'),
        props: (route) => {
            const { id_cod, codigo } = route.params
            var eg = id_cod.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_cod_decry = parseInt(eg)
            var eg2 = codigo.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg2 = CryptoJS.AES.decrypt(eg2, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_cod_decry = parseInt(eg)
            return { id_cod: id_cod_decry, codigo: eg2 }
        },
        meta: {
            title: 'Firmar COD',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites'],
            middlewareEmpresa: ['Empresa con RUEX']
        }
    },
    {
        name: 'codehxml',
        path: '/codehxml/:id_cod/:codigo',
        component: () => import(/* webpackChunkName: "CodEhXml" */ '../components/emision/digital/CodEhXml.vue'),
        props: (route) => {
            const { id_cod, codigo } = route.params
            var eg = id_cod.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_cod_decry = parseInt(eg)
            var eg2 = codigo.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg2 = CryptoJS.AES.decrypt(eg2, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_cod_decry = parseInt(eg)
            return { id_co: id_cod_decry, codigo: eg2 }
        },
        meta: {
            title: 'Firmar COD',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'formrevisionco',
        path: '/formrevisionco/:id_co_solicitud',
        component: () => import(/* webpackChunkName: "FormRevisionCo" */ '../components/emision/FormRevisionCo.vue'),
        props: (route) => {
            const { id_co_solicitud } = route.params
            const { id_co } = route.params
            var eg = id_co_solicitud.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_co_solicitud_decry = parseInt(eg)
            return { id_co_solicitud: id_co_solicitud_decry, id_co: id_co }
        },
        meta: {
            title: 'Revision CO',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'listvencidos',
        path: '/listvencidos',
        component: () => import(/* webpackChunkName: "ListVencidos" */ '../components/vencidos/ListVencidos.vue'),
        meta: {
            title: 'Anular CO',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'mba012',
        path: '/mba012',
        component: () => import(/* webpackChunkName: "HeListCods" */ '../components/pp/Mba012.vue'),
        meta: {
            title: 'Intercom MBA012',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'mba014',
        path: '/mba014',
        component: () => import(/* webpackChunkName: "HeListCods" */ '../components/pp/Mba014.vue'),
        meta: {
            title: 'Intercom MBA012',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'parametroscod',
        path: '/parametroscod',
        component: () => import(/* webpackChunkName: "ParametrosCod" */ '../components/config/ParametrosCod.vue'),
        meta: {
            title: 'Parametros COD',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'reponerco',
        path: '/reponerco',
        component: () => import(/* webpackChunkName: "ReponerCo" */ '../components/reposicion/ReponerCo.vue'),
        meta: {
            title: 'Reponer CO',            
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'listaCoRevisicion',
        path: '/listaCoRevisicion',
        component: () => import(/* webpackChunkName: "ListaCoRepuesto" */ '../components/reposicion/ListaCoRevisicion.vue'),
        meta: {
            title: 'Listado CO repuestos',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'repolista',
        path: '/repolista',
        component: () => import(/* webpackChunkName: "ListaCoRepuesto" */ '../components/reposicion/ListaCoRepuesto.vue'),
        meta: {
            title: 'Listado CO repuestos',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'revisarSolicitudCo',
        path: '/revisarSolicitudCo/:id_co',
        component: () => import(/* webpackChunkName: "RevisionSolicitudCo" */ '../components/reposicion/RevisionSolicitudCo.vue'),
        props: (route) => {
            const { id_co } = route.params
            var eg = id_co.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_co_decry = parseInt(eg)
            return { id_co: id_co_decry }
        },
        meta: {
            title: 'Revisión Reposición CO',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente', 'Representante Legal', 'Registrar y Firmar Trámites', 'Analista UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora', 'Empresa con RUEX']
        }
    },
    {
        name: 'facturasanularlista',
        path: '/facturasanularlista',
        component: () => import(/* webpackChunkName: "FacturasAnularLista" */ '../components/reposicion/FacturasAnularLista.vue'),
        meta: {
            title: 'Listado de Facturas de CO para anular',
            middleware: 'auth',
            middlewareRol: ['Tributación', 'Super Admin'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'recepcionco',
        path: '/recepcionco',
        component: () => import(/* webpackChunkName: "ListEmisionCo" */ '../components/emision/RecepcionCo.vue'),
        meta: {
            title: 'Recepcion CO',
            middleware: 'auth',
            middlewareRol: ['Asistente', 'Certificador', 'Responsable'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
]