import CryptoJS from 'crypto-js';
export default [
    {
        name: 'empresas',
        path: '/misempresas',
        component: () => import(/* webpackChunkName: "AdminEmpresas" */ '../components/empresa/AdminEmpresas.vue'),
        meta: {
            title: 'Mis Empresas',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites', 'Registrar Trámites'],
            middlewareEmpresa: ['All']
        }
    },
    {
        name: 'addempresas',
        path: '/addempresas',
        component: () => import(/* webpackChunkName: "AddEmpresas" */ '../components/empresa/AddEmpresas.vue'),
        meta: {
            title: 'Add Empresa',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites', 'Registrar Trámites'],
            middlewareEmpresa: ['All']
        }
    },
    {
        name: 'verempresas',
        path: '/verempresas/:id_empresa',
        component: () => import(/* webpackChunkName: "ViewEmpresas" */ '../components/empresa/ViewEmpresas.vue'),
        props: (route) => {
            const { id_empresa } = route.params
            var eg = id_empresa.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_empresa_decry = parseInt(eg)
            return { id_empresa: id_empresa_decry }
        },
        meta: {
            title: 'Ver Empresa',
            middleware: 'auth',
            middlewareRol: ['All'],
            middlewareEmpresa: ['All']
        }
    },
    {
        name: 'personal',
        path: '/mipersonal',
        component: () => import(/* webpackChunkName: "UsuariosAdmin" */ '../components/persona/AdminPersonas.vue'),
        meta: {
            title: 'Personal',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites', 'Registrar Trámites'],
            middlewareEmpresa: ['All']
        }
    },
    {
        name: 'addusuarios',
        path: '/addusuarios',
        component: () => import(/* webpackChunkName: "AddUsuarios" */ '../components/persona/AddPersonas.vue'),
        meta: {
            title: 'Add Usuario',
            middleware: 'auth',
            middlewareRol: ['Representante Legal'],
            middlewareEmpresa: ['All']
        }
    },
    {
        name: 'verusuarios',
        path: '/verusuarios/:id_persona',
        component: () => import(/* webpackChunkName: "ViewUsuarios" */ '../components/persona/ViewPersonas.vue'),
        props: (route) => {
            const { id_persona } = route.params
            var eg = id_persona.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_persona_decry = parseInt(eg)
            return { id_persona: id_persona_decry }
        },
        meta: {
            title: 'Ver Usuario',
            middleware: 'auth',
            middlewareRol: ['All'],
            middlewareEmpresa: ['All']
        }
    },
]