export default [
    {
        name: 'graficosindex',
        path: '/graficosindex',
        component: () => import(/* webpackChunkName: "GraficosDdjj" */ '../components/servicios/index.vue'),
        meta: {
            title: 'Graficos Servicios',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites', 'Super Admin', 'Mae', 'Jefatura USP'],
            middlewareEmpresa: ['All']
        }
    },

    {
        name: 'graficosasignaciones',
        path: '/graficosasignaciones',
        component: () => import(/* webpackChunkName: "GraficosDdjj" */ '../components/asignaciones/index.vue'),
        meta: {
            title: 'Graficos Asignaciones',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites', 'Super Admin', 'Mae', 'Jefatura USP'],
            middlewareEmpresa: ['All']
        }
    },

    {
        name: 'graficosingresos',
        path: '/graficosingresos',
        component: () => import(/* webpackChunkName: "GraficosIngresos" */ '../components/ingresos/index.vue'),
        meta: {
            title: 'Graficos Ingresos',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites', 'Super Admin', 'Mae', 'Jefatura USP'],
            middlewareEmpresa: ['All']
        }
    },

    {
        name: 'graficosobservados',
        path: '/graficosobservados',
        component: () => import(/* webpackChunkName: "GraficosObservados" */ '../components/observados/index.vue'),
        meta: {
            title: 'Graficos Observados',
            middleware: 'auth',
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites', 'Super Admin', 'Mae', 'Jefatura USP'],
            middlewareEmpresa: ['All']
        }
    },
]