export default [
    {
        name: 'reporteshabilitados',
        path: '/reporteshabilitados',
        component: () => import(/* webpackChunkName: "HabilitadosReports" */  '../components/HabilitadosReports.vue'),
        meta: {
            title: 'Reportes Habilitados',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Asistente', 'Analista UCO', 'Tributación', 'Mae', 'Jefatura UCO', 'Jefatura UAF','Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'reportessgc',
        path: '/reportessgc',
        component: () => import(/* webpackChunkName: "SgcReports" */  '../components/SgcReports.vue'),
        meta: {
            title: 'Reportes SGC',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Asistente', 'Analista UCO', 'Mae', 'Jefatura UCO', 'Planificación', 'Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'reportesruex',
        path: '/reportesruex',
        component: () => import(/* webpackChunkName: "RuexReports" */  '../components/RuexReports.vue'),
        meta: {
            title: 'Reportes RUEX',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Asistente', 'Analista UCO', 'Mae', 'Jefatura UCO','Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'reportesddjj',
        path: '/reportesddjj',
        component: () => import(/* webpackChunkName: "DdjjReports" */  '../components/DdjjReports.vue'),
        meta: {
            title: 'Reportes DDJJ',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Asistente', 'Analista UCO', 'Mae', 'Jefatura UCO','Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'reportesco',
        path: '/reportesco',
        component: () => import(/* webpackChunkName: "CoReports" */  '../components/CoReports.vue'),
        meta: {
            title: 'Reportes CO',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Asistente', 'Analista UCO', 'Mae', 'Jefatura UCO','Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'reportesoic',
        path: '/reportesoic',
        component: () => import(/* webpackChunkName: "OicReports" */  '../components/OicReports.vue'),
        meta: {
            title: 'Reportes OIC',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Asistente', 'Analista UCO', 'Tributación', 'Mae', 'Jefatura UCO', 'Jefatura UAF','Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'reportesfacturacion',
        path: '/reportesfacturacion',
        component: () => import(/* webpackChunkName: "FacturacionReports" */  '../components/FacturacionReports.vue'),
        meta: {
            title: 'Reportes FACTURACION',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Asistente', 'Tributación', 'Mae', 'Jefatura UAF','Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'reportesqys',
        path: '/reportesqys',
        component: () => import(/* webpackChunkName: "QysReports" */  '../components/QysReports.vue'),
        meta: {
            title: 'Reportes Q&S',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Responsable', 'Mae', 'Secretaria', 'Jefatura UCO', 'Jefatura UCLAJ', 'Jefatura USP', 'Jefatura UAF', 'Planificación'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'reportesseguimiento',
        path: '/reportesseguimiento',
        component: () => import(/* webpackChunkName: "SeguimientoReports" */  '../components/SeguimientoReports.vue'),
        meta: {
            title: 'Reportes SEGUIMIENTO',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Analista UCO', 'Mae','Jefatura USP', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
]