export default [
    {
        name: 'facturacion',
        path: '/facturacion',
        component: () => import(/* webpackChunkName: "Facturacion" */ '../components/Facturacion.vue'),
        meta: {
            title: 'Facturacion',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'solicitudescpt',
        path: '/solicitudescpt',
        component: () => import(/* webpackChunkName: "SolicitudesRuex" */ '../components/SolicitudesCpt.vue'),
        meta: {
            title: 'Solicitudes CPT',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'facturacion_co',
        path: '/facturacion_co',
        component: () => import(/* webpackChunkName: "FacturacionCO" */ '../components/FacturacionCO.vue'),
        meta: {
            title: 'Compra Certificados de Origen',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'facturacion_ser',
        path: '/facturacion_ser',
        component: () => import(/* webpackChunkName: "FacturacionCO" */ '../components/FacturacionSER.vue'),
        meta: {
            title: 'Compra Certificados de Origen',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'facturacion_anulacion',
        path: '/facturacion_anulacion',
        component: () => import(/* webpackChunkName: "FacturacionAnulación" */ '../components/FacturacionAnulacion.vue'),
        meta: {
            title: 'Facturacion Anulación',
            middleware: 'auth',
            middlewareRol: ['Tributación', 'Jefatura UAF'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'facturacion_importar',
        path: '/facturacion_importar',
        component: () => import(/* webpackChunkName: "FacturacionAnulación" */ '../components/FacturacionImportacion.vue'),
        meta: {
            title: 'Facturacion Importación',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'facturacion_reposicion',
        path: '/facturacion_reposicion',
        component: () => import(/* webpackChunkName: "FacturacionReemplazo" */ '../components/FacturacionReemplazo.vue'),
        meta: {
            title: 'Facturacion Reposición',
            middleware: 'auth',
            middlewareRol: ['Tributación', 'Super Admin', 'Jefatura USP', 'Jefatura UAF'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'facturacion_contingencia_deposito',
        path: '/facturacion_contingencia_deposito',
        component: () => import(/* webpackChunkName: "ContingenciaDeposito" */ '../components/FacturacionContingenciaDeposito.vue'),
        meta: {
            title: 'Facturación Contingencia Deposito',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente', 'Super Admin'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'facturacioncontingencia',
        path: '/facturacioncontingencia',
        component: () => import(/* webpackChunkName: "ContingenciaFacturacion" */ '../components/FacturacionContingencia.vue'),
        meta: {
            title: 'Facturacion Contingencia',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
]
