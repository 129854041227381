export default [
    {
        name: 'generarpago',
        path: '/generarpago',
        component: () => import(/* webpackChunkName: "GenerarPago" */ '../components/GenerarPago.vue'),
        meta: {
            title: 'Generar Pago',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'comprarco',
        path: '/comprarco',
        component: () => import(/* webpackChunkName: "GenerarPagoCompraCo" */ '../components/GenerarPagoCompraCo.vue'),
        meta: {
            title: 'Compra Certificados de Origen',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'generarpagoser',
        path: '/generarpagoser',
        component: () => import(/* webpackChunkName: "GenerarPagoServicios" */ '../components/GenerarPagoServicios.vue'),
        meta: {
            title: 'Generar Pago Servicios',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'facturacionanulacion',
        path: '/facturacionanulacion',
        component: () => import(/* webpackChunkName: "FacturaAnulacion" */ '../components/FacturaAnulacion.vue'),
        meta: {
            title: 'Facturacion Anulación',
            middleware: 'auth',
            middlewareRol: ['Tributación', 'Jefatura UAF'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'facturacionrefacturacion',
        path: '/facturacionrefacturacion',
        component: () => import(/* webpackChunkName: "FacturaReFacturar" */ '../components/FacturaReFacturar.vue'),
        meta: {
            title: 'Facturacion Reposición',
            middleware: 'auth',
            middlewareRol: ['Tributación', 'Super Admin', 'Jefatura USP', 'Jefatura UAF'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'facturacioncontingenciadeposito',
        path: '/facturacioncontingenciadeposito',
        component: () => import(/* webpackChunkName: "ContingenciaDeposito" */ '../components/FacturacionContingenciaDeposito.vue'),
        meta: {
            title: 'Facturación Contingencia Deposito',
            middleware: 'auth',
            middlewareRol: ['Certificador', 'Responsable', 'Asistente', 'Super Admin'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'facturacioncontingencia',
        path: '/facturacioncontingencia',
        component: () => import(/* webpackChunkName: "ContingenciaFacturacion" */ '../components/FacturacionContingencia.vue'),
        meta: {
            title: 'Facturacion Contingencia',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Asistente'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
]
