import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import ruex from '../ruex/store';
import auth from '../auth/store';
import ddjj from '../ddjj/store';
import modificaciones from '../modificaciones/store';
import facturacion from '../facturacion/store';

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [
        createPersistedState()
    ],
    modules: {
        auth,
        ruex,
        ddjj,
        modificaciones,
        facturacion
    }
})

export default store
