import CryptoJS from 'crypto-js';
export default [
    {
        name: 'registroqys',
        path: '/registroqys',
        component: () => import(/* webpackChunkName: "FormRegistroQys" */ '../components/registro/FormRegistroQys.vue'),
        meta: {
            title: 'Registro QyS',
            middleware: ['auth',],
            middlewareRol: ['Representante Legal', 'Registrar y Firmar Trámites', 'Registrar Trámites'],
            middlewareEmpresa: ['All']
        }
    },
    {
        name: 'registroqyssnv',
        path: '/registroqyssnv',
        component: () => import(/* webpackChunkName: "FormRegistroQysSnv" */ '../components/registro/FormRegistroQysSnv.vue'),
        meta: {
            title: 'Registro QyS SNV',
            middleware: 'auth',
            middlewareRol: ['Responsable', 'Secretaria'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'listseguimientoqys',
        path: '/listseguimientoqys',
        component: () => import(/* webpackChunkName: "ListSeguiminetoQys" */ '../components/seguimiento/ListSeguiminetoQys.vue'),
        meta: {
            title: 'Lista Seguimiento QyS',
            middleware: 'auth',
            middlewareRol: ['Planificación', 'Mae', 'Super Admin','Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'seguimientoqys',
        path: '/seguimientoqys/:id_qys_solicitud',
        component: () => import(/* webpackChunkName: "SeguimientoQys" */ '../components/seguimiento/SeguimientoQys.vue'),
        props: (route) => {
            const { id_qys_solicitud } = route.params
            var eg = id_qys_solicitud.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_solicitud_decry = parseInt(eg)
            return { id_qys_solicitud: id_solicitud_decry }
        },
        meta: {
            title: 'Revision Qys',
            middleware: 'auth',
            middlewareRol: ['Planificación', 'Mae', 'Super Admin','Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'solicitudesqys',
        path: '/solicitudesqys',
        component: () => import(/* webpackChunkName: "SolicitudesQys" */ '../components/revision/SolicitudesQys.vue'),
        meta: {
            title: 'Solicitudes QyS',
            middleware: 'auth',
            middlewareRol: ['Responsable', 'Jefatura UCLAJ', 'Jefatura UCO', 'Jefatura USP', 'Jefatura UAF', 'Secretaria'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'revisionqys',
        path: '/revisionqys/:id_qys_solicitud',
        component: () => import(/* webpackChunkName: "RevisionQys" */ '../components/revision/RevisionQys.vue'),
        props: (route) => {
            const { id_qys_solicitud } = route.params
            var eg = id_qys_solicitud.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_solicitud_decry = parseInt(eg)
            return { id_qys_solicitud: id_solicitud_decry }
        },
        meta: {
            title: 'Revision Qys',
            middleware: 'auth',
            middlewareRol: ['Responsable', 'Jefatura UCLAJ', 'Jefatura UCO', 'Jefatura USP', 'Jefatura UAF', 'Secretaria'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
    {
        name: 'verqys',
        path: '/verqys/:id_qys',
        component: () => import(/* webpackChunkName: "Qys" */ '../components/seguimiento/Qys.vue'),
        props: (route) => {
            const { id_qys } = route.params
            var eg = id_qys.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            eg = CryptoJS.AES.decrypt(eg, process.env.SECURITY_KEY).toString(CryptoJS.enc.Utf8)
            var id_qys_decry = parseInt(eg)
            return { id_qys: id_qys_decry }
        },
        meta: {
            title: 'Ver Qys',
            middleware: 'auth',
            middlewareRol: ['Planificación', 'Mae', 'Super Admin','Jefatura USP'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    },
]
