export default [
    {
        name: 'login',
        path: '/login',
        component: () => import(/* webpackChunkName: "Login" */'../components/Login.vue'),
        meta: {
            title: 'Iniciar Sesión',
            middleware: 'guest',
            middlewareRol: ['All'],
            middlewareEmpresa: ['All']
        }
    },
    {
        name: 'register',
        path: '/register',
        component: () => import(/* webpackChunkName: "Register" */'../components/Register.vue'),
        meta: {
            title: 'Registro',
            middleware: 'guest',
            middlewareRol: ['All'],
            middlewareEmpresa: ['All']
        }
    },
    {
        name: 'selectEmpresa',
        path: '/seleccionarempresa',
        component: () => import(/* webpackChunkName: "SelectEmpresa" */  '../components/SelectEmpresa.vue'),
        meta: {
            title: 'Seleccionar Empresa',
            middleware: 'auth',
            middlewareRol: ['All'],
            middlewareEmpresa: ['All']
        }
    }
]