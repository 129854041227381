export default [
    {
        name: 'adsib',
        path: '/adsib',
        component: () => import(/* webpackChunkName: "Adsib" */  '../../helpers/components/Adsib.vue'),
        meta: {
            title: 'Lista RUEX',
            middleware: 'auth',
            middlewareRol: ['Super Admin', 'Certificador', 'Responsable', 'Asistente', 'Analista UCO', 'Jefatura USP', 'Jefatura UCO'],
            middlewareEmpresa: ['Institución Revisora/Certificadora']
        }
    }
]
